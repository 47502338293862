import React, { memo, ReactElement, useEffect, useState } from 'react';
import styles from './styles.legacy.css';
import classnames from 'classnames';
import Icon from '../../../Icon';
import NookDropdown from '../NookDropdown';
import NextArticle from '../NextArticle';

const NookButton = ({ fromHeaderBar, isMobile }): ReactElement => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${styles.Wrapper}`)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={classnames(styles.Wrapper, {
          [styles.Open]: isDropdownOpen,
        })}
      >
        <div
          className={styles.Button}
          role="presentation"
          onClick={() => toggleDropdown()}
        >
          <Icon
            type={'IconArrowDown'}
            addClass={classnames(styles.Icon, {
              [styles.IsMobile]: isMobile,
            })}
          />
          Ins nOOkiversum
        </div>
        <NookDropdown
          isOpen={isDropdownOpen}
          setOpen={setIsDropdownOpen}
          isMobile={isMobile}
        />
      </div>
      {!fromHeaderBar && <NextArticle />}
    </>
  );
};

export default memo(NookButton);
