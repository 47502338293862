import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './styles.legacy.css';
import classnames from 'classnames';
import heart from '../../../../assets/heart.svg';
import play from '../../../../assets/play_blue.png';
import Lottie from 'react-lottie';
import einsteinAudio from '../../../../assets/audio/einstein.mp3';
import fridaAudio from '../../../../assets/audio/frida.mp3';

const FloatingPlayer = ({ data, narrator }): ReactElement => {
  const [isPlaying, setIsPlaying] = useState(true);
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioSource = narrator === 'Einstein' ? einsteinAudio : fridaAudio;

  const togglePlayPause = useCallback(() => {
    setIsPlaying(!isPlaying);
    audioRef?.current[isPlaying ? 'pause' : 'play']();
  }, [isPlaying]);

  const options = useMemo(
    () => ({
      animationData: data,
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [data],
  );

  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.play();
    }
  }, []);

  return (
    <>
      <img
        className={styles.LikeButton}
        role="presentation"
        onClick={() => {}}
        src={heart}
        alt="heart"
      />
      <div className={styles.Lottie}>
        <Lottie options={options} height={170} width={170} />
      </div>
      <div
        className={classnames(styles.Button)}
        role="presentation"
        onClick={() => togglePlayPause()}
      >
        <div className={styles.Icon}>
          <img src={play} alt="play" />
        </div>
        {narrator} fasst zusammen
      </div>
      {isPlaying && (
        <audio ref={audioRef}>
          <track default kind="captions" srcLang="en" src="" />
          <source src={audioSource} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </>
  );
};

export default memo(FloatingPlayer);
