import { useEffect, useState } from 'react';

export const useInViewBySelector = (elementSelector) => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const element = document.querySelector(elementSelector);
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsInView(true);
        } else {
          setIsInView(false);
        }
      },
      { threshold: 1.0 },
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [elementSelector]);

  return isInView;
};

export const getScrllyByPath = (pathname) => {
  for (const key in scrllies) {
    if (pathname.includes(key)) {
      return scrllies[key];
    }
  }

  return null;
};

export const scrllies = {
  '/politik/die-mar-vom-verarmten-mittelstand-698426': {
    id: 'bc369d68-79a6-465e-9c0b-886243b79973',
    js: {
      host: 'handelszeitung.scrlly.com',
      slots: [
        {
          selector: '#scrlly-bc369d68-79a6-465e-9c0b-886243b79973',
          id: 'bc369d68-79a6-465e-9c0b-886243b79973',
        },
      ],
    },
  },
  '/unternehmen/der-traum-vom-grunen-fliegen-wird-sehr-teuer-692810': {
    id: '2d6fa162-80ea-4055-b68f-b5556a3b9748',
    js: {
      host: 'handelszeitung.scrlly.com',
      slots: [
        {
          selector: '#scrlly-2d6fa162-80ea-4055-b68f-b5556a3b9748',
          id: '2d6fa162-80ea-4055-b68f-b5556a3b9748',
        },
      ],
    },
  },
  '/panorama/so-lauft-das-self-storage-geschaft-in-der-schweiz-672145': {
    id: '6d96eb41-febd-428a-af3a-f84eb8f1548d',
    js: {
      host: 'handelszeitung.scrlly.com',
      slots: [
        {
          selector: '#scrlly-6d96eb41-febd-428a-af3a-f84eb8f1548d',
          id: '6d96eb41-febd-428a-af3a-f84eb8f1548d',
        },
      ],
    },
  },
};
