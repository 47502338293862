import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React, { ReactElement } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { getServiceUrl } from '../../../shared/helpers/serviceUrl';
import { noop } from '../../../shared/helpers/utils';
import SSRContextProvider from '../../components/SSRContext';
import asyncConfigureApolloClient from '../../../shared/asyncConfigureApolloClient';
import type { ClientFactoryOptions } from './typings';
import { StableNavigateContextProvider } from '../../../shared/hooks/useStableNavigateContext';

// The graphql host is passed into the template from an environment variable.
let graphqlHost = window.__GRAPHQL_HOST__;
const graphqlOrigin = window.__GRAPHQL_ORIGIN__;
const host = window.location.host;

if (__FORCE_PREVIEW_REQUESTS__) {
  graphqlHost = process.env.__PREVIEW_GRAPHQL_HOST__ || '';
  if (
    !process.env.__PREVIEW_GRAPHQL_HOST__ &&
    __DEVELOPMENT__ &&
    __DOT_ENV__ !== 'stage' &&
    __DOT_ENV__ !== 'master'
  ) {
    // eslint-disable-next-line no-console
    console.log(
      'WARNING: __PREVIEW_GRAPHQL_HOST__ is only available on stage or master!',
    );
  }
}

const clientFactory =
  ({
    RaschProviders,
    AutoUpdateProvider = null,
    AppRoutes,
    configureClientStore,
    mountNode,
    preRender = noop,
    generateApolloCache,
  }: ClientFactoryOptions) =>
  async () => {
    // setup datadoglogs
    if (
      __DATADOG_CLIENT_TOKEN__ &&
      __DATADOG_ENV__ &&
      __DATADOG_SAMPLE_RATE__ &&
      !window.location.hostname.includes('localhost')
    ) {
      datadogLogs.init({
        clientToken: __DATADOG_CLIENT_TOKEN__,
        service: 'rasch-stack',
        site: 'datadoghq.eu',
        env: __DATADOG_ENV__,
        forwardErrorsToLogs: true,
        sessionSampleRate: parseInt(__DATADOG_SAMPLE_RATE__),
      });

      datadogLogs.addLoggerGlobalContext('publication', __APP__);
    }

    // push location origin to global scope
    global.locationOrigin =
      global.location.origin ||
      `${global.location.protocol}//${global.location.hostname}${
        global.location.port ? `:${global.location.port}` : ''
      }`;

    const apolloClient = await asyncConfigureApolloClient(
      getServiceUrl(graphqlHost),
      graphqlOrigin,
      host,
      generateApolloCache,
    );

    // Make the client globally available for content prefetching
    global.apolloClient = apolloClient;

    const store = configureClientStore();
    const initialState = store.getState();
    initialState.route.clientUrl = window.origin;

    const Root = (): ReactElement => (
      <Provider store={store}>
        <SSRContextProvider>
          <BrowserRouter>
            <StableNavigateContextProvider>
              {(RaschProviders && <RaschProviders />) || null}
              <ApolloProvider client={apolloClient}>
                <>
                  {(AutoUpdateProvider && <AutoUpdateProvider />) || null}
                  <HelmetProvider>{AppRoutes && <AppRoutes />}</HelmetProvider>
                </>
              </ApolloProvider>
            </StableNavigateContextProvider>
          </BrowserRouter>
        </SSRContextProvider>
      </Provider>
    );

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const render = () => require('./render').default(mountNode, Root);

    // Do the pre render passed function
    await preRender();

    // Remove the global image intersection observer defined in the template, if it's there
    if ('imageObserver' in global) {
      // @ts-ignore
      global.imageObserver.disconnect();
    }

    // Do the initial rendering.
    render();
  };

export default clientFactory;
