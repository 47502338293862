import React, { memo, ReactElement } from 'react';
import styles from './styles.legacy.css';
import Icon from '../../../Icon';
import { useLocation } from 'react-router-dom';
import Link from '../../../../../../../common/components/Link';

const articles = [
  '/politik/die-mar-vom-verarmten-mittelstand-698426',
  '/unternehmen/der-traum-vom-grunen-fliegen-wird-sehr-teuer-692810',
  '/panorama/so-lauft-das-self-storage-geschaft-in-der-schweiz-672145',
  '/geld/wiedikon-wollishofen-zurich-west-das-sind-die-trendviertel-der-stadt-zurich-610464',
  '/panorama/wohnen-im-wirtschaftsstandort-basel-das-sind-die-trendquartiere-612538',
];

const NextArticle = (): ReactElement => {
  const location = useLocation();
  const idx = articles.indexOf(location.pathname);
  const nextArticle =
    idx === articles.length - 1 ? articles[0] : articles[idx + 1];

  // const prevArticle =
  //   idx === 0 ? articles[articles.length - 1] : articles[idx - 1];

  return (
    <Link path={nextArticle} className={styles.Wrapper}>
      Nächster
      <br />
      Artikel
      <Icon type={'IconArrowRight'} />
    </Link>
  );
};

export default memo(NextArticle);
