import React, { memo, ReactElement } from 'react';
import styles from './styles.legacy.css';
import * as frida from '../../assets/lottie/Frida.json';
import * as einstein from '../../assets/lottie/Einstein.json';
import FloatingPlayer from './components/FloatingPlayer';

const AudioSummary = ({ clickedWhichAudio }): ReactElement => {
  return (
    <div className={styles.Wrapper}>
      {clickedWhichAudio === 'einstein' && (
        <FloatingPlayer data={einstein} narrator="Einstein" />
      )}
      {clickedWhichAudio === 'frida' && (
        <FloatingPlayer data={frida} narrator="Frida" />
      )}
    </div>
  );
};

export default memo(AudioSummary);
