// @ts-ignore TS1208 -- This file is an isolated module
// FIXME Duplicated in consts/search-params
//       Build embed.ts the same way we build other scripts (Next.js’
//       WebPack) and import it from there.
const PARENT_HOST = 'parent'; // Extend the HTMLIFrameElement interface
function logError(error) {
  if ('console' in window && 'error' in window.console) {
    window.console.error('SCRLLY', error);
  }
}
export const inject = (config) => {
  if (!config || typeof config !== 'object') {
    logError(new Error('Confing missing'));
  } else {
    const { host, slots, disableStyles } = config;
    slots.forEach(({ id, selector, loading }) => {
      try {
        const placement = document.querySelector(selector);
        if (!placement) {
          logError(new Error(`Requested placement not found: ${selector}`));
        } else {
          const iframe = document.createElement('iframe');
          const src = new URL(
            `/stories/${encodeURIComponent(id)}`,
            `https://${encodeURIComponent(host)}`,
          );
          src.searchParams.set(PARENT_HOST, window.location.host);
          iframe.src = src.toString();
          iframe.allow = 'fullscreen';
          iframe.allowFullscreen = true;
          iframe.referrerPolicy = 'origin-when-cross-origin';
          if (!disableStyles) {
            const style = {
              margin: '0 auto',
              width: '100%',
              height: '90vh',
              minHeight: '360px',
              maxHeight: '1240px',
              border: 'none',
            };
            Object.assign(iframe.style, style);
          }
          if (loading) {
            iframe.loading = loading;
          }
          placement.appendChild(iframe);
        }
      } catch (error) {
        logError(error);
      }
    });
  }
};
