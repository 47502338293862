import React, { memo, ReactElement, useEffect, useRef } from 'react';
import styles from './styles.legacy.css';
import classnames from 'classnames';
import { getScrllyByPath, useInViewBySelector } from '../../helpers';
import { useLocation } from 'react-router-dom';
import { inject } from '../../scrlly-embed.js';
import Slideshow from '../Slideshow';
import Icon from '../../../Icon';

const NookFullscreen = ({ onClose, page }): ReactElement => {
  const ref = useRef<HTMLDivElement>();
  const location = useLocation();
  const [offsetTop, setOffsetTop] = React.useState(0);
  const [scrlly, setScrlly] = React.useState(
    getScrllyByPath(location.pathname),
  );
  const partnerWithinHeader =
    '.is-in-view .is-in-view div:has([aria-label="Logo for partner Bilanz"])';
  const partnerInView = useInViewBySelector(partnerWithinHeader);

  useEffect(() => {
    const isSticky = !!document.querySelector('header[class*=IsSticky]');
    const header = document.querySelector('.site-header');
    const nav = document.querySelector('.header-inner nav');
    const headerBar = document.querySelector(
      '.header-inner > div:has(.utility-bar-wrapper)',
    );
    let offset = 0;

    if (isSticky) {
      offset = (nav?.clientHeight || 0) + (headerBar?.clientHeight || 0);
    } else {
      offset = partnerInView
        ? header?.clientHeight || 0
        : nav?.clientHeight || 0;
    }
    setOffsetTop(offset);
  }, [partnerInView]);

  useEffect(() => {
    if (page === 'scrlly' && location.pathname && scrlly) {
      setScrlly(scrlly);
      inject(scrlly.js);
    }

    return () => {
      setScrlly(null);
    };
  }, [page, scrlly, location.pathname]);

  return (
    <div
      ref={ref}
      id="nook-fullscreen"
      style={{ top: offsetTop }}
      className={styles.Wrapper}
    >
      <span
        role="presentation"
        onClick={onClose}
        className={styles.CloseButton}
      />

      <div className={styles.InnerWrapper}>
        <aside className={styles.Sidebar}>
          <ul>
            <li className={classnames(styles.ListItem, styles.ThreeSixty)}>
              nOOk 360°
            </li>
            <li className={classnames(styles.ListItem, styles.Formats)}>
              nOOk formats
            </li>
            <li className={classnames(styles.ListItem, styles.Mingle)}>
              nOOk mingle
            </li>
          </ul>
        </aside>
        <div className={styles.Content}>
          <header>nOOk</header>
          {scrlly && <div id={`scrlly-${scrlly?.id}`}></div>}
          {!scrlly && !['360', 'mingle'].includes(page) && <Slideshow />}

          {page === '360' && (
            <div
              className={styles.Button}
              role="presentation"
              onClick={() =>
                global?.open(
                  'https://nook-42462.bubbleapps.io/version-test/',
                  '_blank',
                )
              }
            >
              <Icon type={'IconArrowRight'} />
              Visit 360° format
            </div>
          )}
          {page === 'mingle' && (
            <div
              className={styles.Button}
              role="presentation"
              onClick={() =>
                global?.open(
                  'https://nook-42462.bubbleapps.io/version-test/mingle',
                  '_blank',
                )
              }
            >
              <Icon type={'IconArrowRight'} />
              Visit mingle format
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(NookFullscreen);
