import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './styles.legacy.css';
import classnames from 'classnames';
import NookFullscreen from '../NookFullscreen';
import Link from '../../../../../../../common/components/Link';
import AudioSummary from '../AudioSummary';
import glasses from '../../assets/glasses.png';
import globe from '../../assets/globe.png';
import hand from '../../assets/hand.png';
import einstein from '../../assets/einstein.png';
import frida from '../../assets/frida.png';
import play from '../../assets/play.svg';
import { Helmet } from 'react-helmet-async';

const NookDropdown = ({ isOpen, setOpen, isMobile }): ReactElement => {
  const dropdownRef = useRef<HTMLDivElement>();
  const [scrlly] = React.useState(null);
  const [isFullscreenVisible, setFullscreenVisible] = useState(false);
  const [fullScreenPage, setFullScreenPage] = useState(null);
  const [isAudioVisible, setAudioVisible] = useState(false);
  const [clickedWhichAudio, setClickedWhichAudio] = useState(null);

  const openNookFullscreen = useCallback(
    (page) => {
      setFullscreenVisible(!isFullscreenVisible);
      setFullScreenPage(page);
    },
    [isFullscreenVisible],
  );

  const handleAudioClick = useCallback(
    (event) => {
      if (event.target.closest('img[alt="einstein"]')) {
        setClickedWhichAudio('einstein');
      } else {
        setClickedWhichAudio('frida');
      }
      setAudioVisible(true);
      setOpen(false);
    },
    [setOpen],
  );

  useEffect(() => {
    const body = document.querySelector('body');
    const ads = document.querySelectorAll(
      '[class*=Ad]',
    ) as NodeListOf<HTMLDivElement>;

    const addInlineStyles = () => {
      body.style.overflow = 'hidden';
      ads.forEach((ad) => {
        ad.style.zIndex = '1';
      });
    };

    const resetStyles = () => {
      ads.forEach((ad) => {
        ad.style.removeProperty('z-index');
      });
      body.style.removeProperty('overflow');
    };

    if (isFullscreenVisible) {
      addInlineStyles();
      setOpen(false);
    } else {
      resetStyles();
    }
    return () => {
      resetStyles();
    };
  }, [isFullscreenVisible, setOpen]);

  return (
    <>
      {scrlly && (
        <Helmet>
          <script type="text/javascript">{scrlly?.js}</script>
          <script src="https://cdn.scrlly.com/embed.js" async></script>
        </Helmet>
      )}
      {!isOpen && isAudioVisible && (
        <AudioSummary clickedWhichAudio={clickedWhichAudio} />
      )}
      {isOpen && (
        <div
          ref={dropdownRef}
          role="presentation"
          id="nook-button"
          className={classnames(styles.Wrapper, {
            [styles.Open]: isOpen,
            [styles.IsMobile]: isMobile,
          })}
        >
          <div
            onClick={handleAudioClick}
            role="presentation"
            className={classnames(styles.ListItem, styles.ListItem)}
          >
            <div className={styles.Icon}>
              <img src={play} alt="play" />
            </div>
            <span>
              Kurz & <br />
              knapp mit <img src={frida} alt="frida" role="presentation" />
              <img src={einstein} alt="einstein" />
            </span>
          </div>
          <div
            onClick={() => openNookFullscreen('scrlly')}
            role="presentation"
            className={classnames(styles.ListItem, styles.ListItem)}
          >
            <div className={styles.Icon}>
              <img src={hand} alt="hand" />
            </div>
            <span>Scrolly-Erkundung starten</span>
          </div>
          <div
            onClick={() => openNookFullscreen('360')}
            role="presentation"
            className={classnames(styles.ListItem, styles.Dark)}
          >
            <div className={classnames(styles.Icon, styles.Dark)}>
              <img src={globe} alt="globe" />
            </div>
            <span>nOOk 360°</span>
          </div>
          <div
            onClick={() => openNookFullscreen('mingle')}
            role="presentation"
            className={classnames(styles.ListItem, styles.Dark)}
          >
            <div className={classnames(styles.Icon, styles.Dark)}>
              <img src={globe} alt="globe" />
            </div>
            <span>nOOk mingle</span>
          </div>
          <div className={classnames(styles.ListItem, styles.Dark)}>
            <div className={classnames(styles.Icon, styles.Dark)}>
              <img src={glasses} alt="glasses" />
            </div>
            <Link path="/nook/ueber-uns">
              <span>Wer wir sind</span>
            </Link>
          </div>
        </div>
      )}
      {isFullscreenVisible && (
        <NookFullscreen
          onClose={() => {
            setFullscreenVisible(false);
            setOpen(false);
          }}
          page={fullScreenPage}
        />
      )}
    </>
  );
};

export default memo(NookDropdown);
