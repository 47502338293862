import React, { memo, useRef, useState } from 'react';
import styles from './styles.legacy.css';
import Icon from '../../../Icon';

const slides = [
  {
    src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    title: 'Big Buck Bunny',
    description: 'Big Buck Bunny tells the story of a giant rabbit',
  },
  {
    src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
    title: 'Elephants Dream',
    description: 'The first Blender Open Movie from 2006',
  },
  {
    src: 'https://storage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4',
    title: 'Tears of Steel',
    description:
      'A short film by the Blender Institute, part of the Open Movie Project.',
  },
];

const Slideshow = () => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const [currentSrc, setCurrentSrc] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const currentSlide = slides[currentSlideIdx];

  React.useEffect(() => {
    if (currentSlide) {
      setCurrentSrc(currentSlide?.src);
    }
  }, [currentSlide]);

  const handlePrevNext = (incr) => {
    if (currentSlideIdx + incr < 0) {
      setCurrentSlideIdx(slides.length - 1);
    } else if (currentSlideIdx + incr >= slides.length) {
      setCurrentSlideIdx(0);
    } else {
      setCurrentSlideIdx((prev) => prev + incr);
    }
    setCurrentSrc('');
  };

  const handlePlayPause = () => {
    if (videoRef?.current?.paused) {
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
    }
  };

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Slide}>
          {currentSrc && (
            <video ref={videoRef}>
              <track default kind="captions" srcLang="en" src="" />
              <source src={currentSrc} type="video/mp4" />
              Your browser does not support the video.
            </video>
          )}

          <div className={styles.Footer}>
            <h2>{currentSlide?.title}</h2>
            <p>{currentSlide?.description}</p>
          </div>
        </div>

        <div
          role="presentation"
          className={styles.Prev}
          onClick={() => handlePrevNext(-1)}
        >
          <Icon type="IconArrowLeft" />
        </div>
        <div
          role="presentation"
          className={styles.Next}
          onClick={() => handlePrevNext(1)}
        >
          <Icon type="IconArrowRight" />
        </div>
        <div
          role="presentation"
          className={styles.Play}
          onClick={handlePlayPause}
        ></div>
      </div>
    </>
  );
};

export default memo(Slideshow);
